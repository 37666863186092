// 添加询单
import http from '../http';

// 左边数据
export function leftNewOrder (params) {
    return http({
        url: "/api/order/newOrder",
        method: "GET",
        params:params
    })
}

// 右边数据
export function rightNewOrder (params) {
    return http({
        url: "/api/order/newOrder",
        method: "GET",
        params:params
    })
}

// 作废
export function delNewOrder (data) {
    return http({
        url: "/api/order/newOrder",
        method: "DELETE",
        data:data
    })
}

//成本中心 新询单报价
export function offerWholePut (data) {
    return http({
        url: "/api/order/offerWhole",
        method: "PUT",
        data:data
    })
}

//成本中心 修改新询单报价
export function offerWholePost (data) {
    return http({
        url: "/api/order/offer",
        method: "PUT",
        data:data
    })
}

//权限2 修改
export function newOrderPost (data) {
    return http({
        url: "/api/order/newOrder",
        method: "POST",
        data:data
    })
}
//权限2 确定报价
export function newOrderPut (data) {
    return http({
        url: "/api/order/offer",
        method: "PUT",
        data:data
    })
}

//权限1 修改发送报价
export function newOrderPost1 (data) {
    return http({
        url: "/api/order/offer",
        method: "post",
        data:data
    })
}

//权限1 确定询单
export function newOrderPost2 (data) {
    return http({
        url: "/api/order/offerWait",
        method: "post",
        data:data
    })
}

//订单管理 订单详细
export function orderDetail (params) {
    return http({
        url: "/api/order/orderDetail",
        method: "GET",
        params:params
    })
}

//订单管理 操作提交 dialog
export function orderDetailOperate (data) {
    return http({
        url: "/api/order/operate",
        method: "POST",
        data:data
    })
}

//采购端历史导入
export function historyImport1Get (params) {
    return http({
        url: "api/order/historyOffer",
        method: "get",
        params:params
    })
}

//采购端确定修改采购员
export function changeOrderBuyer (data) {
    return http({
        url: "/api/order/changeOrderBuyer",
        method: "post",
        data:data
    })
}

//订单详细 修改
export function orderDetailEdit (data) {
    return http({
        url: "/api/order/orderDetailEdit",
        method: "post",
        data:data
    })
}



