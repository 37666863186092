<template>
  <div>
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>历史报价列表</span>
      </div>
    </div>
    <!--tab-->
    <div class="tab pt-12">
      <el-tabs v-model="tabActiveName">
        <el-tab-pane name="1">
          <span slot="label">全部</span>
        </el-tab-pane>
      </el-tabs>
      <!--<div class="tab-search historical-quotes-search">
        <el-input
            placeholder="请输入品牌中文"
            v-model="searchProp.brand_ch"
        >
        </el-input>
        <el-input
            placeholder="请输入品牌英文"
            v-model="searchProp.brand_en">
        </el-input>
        <el-input
            placeholder="品名规格型号"
            v-model="searchProp.spec">
        </el-input>
        <el-button type="primary" @click="searchClick">搜索</el-button>
      </div>-->
    </div>
    <div class="historical-quotes-button">
      <el-button type="primary" @click="historicalDialogButton">过滤表格</el-button>
    </div>
    <div class="supplier-management-table">
      <el-table
          ref="table"
          :data="historicalData"
          border
          style="width: 100%"
          :row-class-name="tableRowClassName"
          :header-cell-style="{
                      'color': '#18a78a'}"
          v-loading="$store.state.loading"
      >
        <template v-for="(item,key) in historicalDataTitle">
          <el-table-column
              :key="key"
              :label="item.name"
              :prop="item.field"
              min-width="140px"
              align="center"
              v-if="filterActivate.indexOf(key)!=-1"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>

    <el-dialog
        :append-to-body="true"
        title="过滤表格"
        :visible.sync="historicalDialog"
        width="440px"
        :before-close="historicalClose"
        :close-on-press-escape="false"
    >
      <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
      >
        全选
      </el-checkbox>
      <el-checkbox-group
          v-model="filterActivate"
          class="historical-quotes-check"
          @change="handleCheckedChange"
      >
        <template v-for="(item,key) in historicalDataTitle">
          <el-checkbox
              :label="key"
              :key="key"
              v-if="item.competence.indexOf($store.state.currentPermissions)!==-1"
          >
            {{ item.name }}
          </el-checkbox>
        </template>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="historicalClose">取 消</el-button>
        <el-button type="primary" @click="historicalDialog=false">确 定</el-button>
      </div>
    </el-dialog>
    <div class="footerPage">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="30"
          layout="total, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {historicalOffer} from '@/api/module/historicalQuotes'
import {historyImport1Get} from "@/api/module/inquiryList";

export default {
  components: {},
  props: [],
  data() {
    return {
      searchProp:{
        brand_ch:'',
        brand_en:'',
        spec:''
      },
      historicalDialog: false,
      tabActiveName: '1',
      total: 0,
      page:1,
      keyword: '',//搜索的值
      isIndeterminate: false,// 全选
      checkAll: true, //全选
      filterData: [], // 过滤数据
      filterActivate: [], // 过滤数据 当前选择哪个
      filterSave: null, //显示的时候先把数据存起来，不然按取消会还原
      historicalDataTitle: [{
        name: '询单编号',
        field: 'inquiryNumber',
        competence: [1, 2, 3] //页面权限
      }, {
        name: '品牌（中文）',
        field: 'brand_ch',
        competence: [1, 2, 3]
      }, {
        name: '品牌（英文）',
        field: 'brand_en',
        competence: [1, 2, 3]
      }, {
        name: '品名/规格型号/单位',
        field: 'spec',
        competence: [1, 2, 3]
      }, {
        name: '数量',
        field: 'number',
        competence: [1, 2, 3]
      }, {
        name: '供应商名称',
        field: 'supplierName',
        competence: [1]
      }, {
        name: '折扣单价',
        field: 'discountUnitPrice',
        competence: [1]
      }, {
        name: '币种',
        field: 'currency',
        competence: [1]
      }, {
        name: '折扣',
        field: 'discount',
        competence: [1]
      }, {
        name: '单件重量',
        field: 'unitOfWeight',
        competence: [1]
      }, {
        name: '重量单位',
        field: 'grossWeight',
        competence: [1]
      }, {
        name: '总重量',
        field: 'grossWeight',
        competence: [1],
      }, {
        name: '包装尺寸',
        field: 'packageDimensions',
        competence: [1]
      }, {
        name: '包装单位',
        field: 'packingUnit',
        competence: [1]
      }, {
        name: '货期',
        field: 'deliveryTime',
        competence: [1]
      }, {
        name: '货期单位',
        field: 'deliveryUnit',
        competence: [1]
      }, {
        name: '销售单价',
        field: 'unitPrice',
        competence: [2]
      }, {
        name: '销售总价',
        field: 'totalSalePrice',
        competence: [2]
      }, {
        name: '业务员',
        field: 'salesman',
        competence: [2]
      }, {
        name: '报价日期',
        field: 'timeQuotes',
        competence: [1, 2]
      }, {
        name: '采购员',
        field: 'buyer',
        competence: [3]
      }, {
        name: '采购报价单价',
        field: 'purchaseQuotationPrice',
        competence: [3]
      }, {
        name: '采购币种',
        field: 'currency1',
        competence: [3]
      }, {
        name: '总价',
        field: 'totalPrice',
        competence: [3]
      }, {
        name: '供应商',
        field: 'supplier',
        competence: [3]
      }, {
        name: '报价日期',
        field: 'offerDate',
        competence: [3]
      }, {
        name: '销售报价单价',
        field: 'salesQuotationPrice',
        competence: [3]
      }, {
        name: '销售币种',
        field: 'currency2',
        competence: [3]
      }, {
        name: '销售总价',
        field: 'totalSalePrice2',
        competence: [3]
      }, {
        name: '销售报价日期',
        field: 'salesQuotationDate',
        competence: [3]
      }],
      historicalData: [
      /*  {
          //权限1
          inquiryNumber: '111',//询单编号
          brand_ch: '1', //品牌（中文）
          brand_en: '2', //品牌（英文）
          spec: '3', //品名规格型号
          number: '4', //数量
          supplierName: '',//供应商名称
          discountUnitPrice: '999',//折扣单价
          currency: '999',//币种
          discount: '999',//折扣
          unitWeight: '999',//单件重量
          unitOfWeight: '999',//重量单位
          grossWeight: '999',//总重量
          packageDimensions: '999',//包装尺寸
          packingUnit: '999',//包装单位
          deliveryTime: '999',//货期
          deliveryUnit: '999',//货期单位

          // 权限2
          unitPrice: '销售单价',// 销售单价
          totalSalePrice: '销售总价',// 销售总价
          salesman: '业务员',// 业务员
          timeQuotes: '123132',//报价时间  权限 1和2相同

          // 权限3
          buyer: '采购员',//采购员
          purchaseQuotationPrice: '',//采购报价单价
          currency1: '采购报价单价',//采购币种
          totalPrice: '总价',//总价采购
          supplier: '供应商',//供应商
          offerDate: '报价日期采购',//报价日期采购
          salesQuotationPrice: '销售报价单价',//销售报价单价
          currency2: '销售币种',//销售币种
          totalSalePrice2: '销售总价',//销售总价
          salesQuotationDate: '销售报价日期',//销售报价日期
          status: '状态',//状态
        }*/
      ]
    };
  },
  mounted() {
    this.initLoad()
    console.log(this.$store.state.currentPermissions)
    for (let i = 0; i < this.historicalDataTitle.length; i++) {
      if (this.historicalDataTitle[i].competence.indexOf(this.$store.state.currentPermissions) !== -1) {
        this.filterData.push(i);
        this.filterActivate.push(i);
      }
    }
    // filterData: [], // 过滤数据
    // filterActivate: [], // 过滤数据 当前选择哪个
    switch (this.$store.state.currentPermissions) {
      case 1:
        break
      case 2:
        break
      case "3":
        break
    }

  },
  methods: {
    //点击搜索
    searchClick(){
   /*   total: 0,
          page:1,*/
      let params={
        page:this.page,
        brand_ch:this.searchProp.brand_ch,
        brand_en:this.searchProp.brand_en,
        spec:this.searchProp.spec,
      }
      historyImport1Get(params).then((result) => {
        console.log(result)
      })
    },
    // 初始化
    initLoad() {
      this.$store.state.loading=true;
      let params = {
        page: this.page,
      }
      historicalOffer(params).then((result) => {
        this.total=result.data.total;
        this.historicalData = result.data.data.map(item => ({
          inquiryNumber: item.seek_no,//询单编号
          brand_ch: item.brand_ch, //品牌（中文）
          brand_en: item.brand_en, //品牌（英文）
          spec: item.spec, //品名规格型号
          number: item.number, //数量
          supplierName: item.supplier_name,//供应商名称
          discountUnitPrice: item.purchase_money,//折扣单价
          currency: item.currency,//币种
          discount: item.discount,//折扣
          unitWeight: item.weight,//单件重量
          unitOfWeight: item.weight_unit,//重量单位
          grossWeight: item.weight_total,//总重量
          packageDimensions: item.pack_size,//包装尺寸
          packingUnit: item.pack_unit,//包装单位
          deliveryTime: item.delivery_date,//货期
          deliveryUnit: item.delivery_date_unit,//货期单位
          // 权限2
          unitPrice: item.order_spec.sale_money,// 销售单价
          totalSalePrice: item.order_spec.sale_money_total,// 销售总价
          salesman: item.center_name,// 业务员
          timeQuotes:this.Utils.timeDate(item.center_reocrd_date),//报价时间  权限 1和2相同

          // 权限3
          buyer: item.buyer_name,//采购员
          purchaseQuotationPrice: item.purchase_money,//采购报价单价
          currency1: item.currency,//采购币种
          totalPrice: item.total_money,//总价采购
          supplier: item.supplier_name,//供应商
          offerDate: this.Utils.timeDate(item.created_at),//报价日期采购
          salesQuotationPrice: item.order_spec.sale_money,//销售报价单价
          currency2: '人民币',// item.currency,//销售币种
          totalSalePrice2: item.order_spec.sale_money_total,//销售总价
          salesQuotationDate: this.Utils.timeDate(item.center_reocrd_date),//销售报价日期
          status: item.aa,//状态
        }));
        this.$store.state.loading=false;
      })
    },
    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.initLoad();
    },
    //全选
    handleCheckAllChange(val) {
      this.filterActivate = val ? this.filterData : [];
      this.isIndeterminate = false;
    },
    //单选
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.filterData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filterData.length;
    },
    // dialog 点击显示
    historicalDialogButton() {
      this.filterSave = [...this.filterActivate];
      this.historicalDialog = true;
    },
    //dialog 关闭时调用
    historicalClose() {
      console.log(123)
      this.historicalDialog = false;
      this.filterActivate = this.filterSave;
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
  },
};
</script>
<style lang="scss">
.historical-quotes-search{
  .el-input{
    width: 150px;
    margin-right: 12px;
  }
}
</style>
<style lang="scss" scoped>
.tab-search{
  @include flex();
  width: auto;
}
.historical-quotes-button {
  text-align: right;
  margin-bottom: 12px;
}

.historical-quotes-check {
  @include flex(row, wrap);

  .el-checkbox {
    margin-right: 0;
    width: 33%;
    margin-top: 8px;
  }

}
</style>
