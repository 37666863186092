// 历史记录
import http from '../http';

//获取角色列表
export function historicalOffer (params) {
    return http({
        url: "/api/order/offer",
        method: "GET",
        params:params
    })
}
